export default defineComponent({
  props: {
    parentTab: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    mainsections: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    esgAssessments: {
      type: Array,
      default: []
    },
    esgQuestionnaires: {
      type: Array,
      default: []
    }
  },
  setup: function setup() {
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    return {
      $store: $store
    };
  },
  data: function data() {
    return {
      activeTab: 0
    };
  }
});